import blog1 from "../../images/blog/blog-1-1.jpg";
import blog2 from "../../images/blog/blog-1-2.jpg";
import blog3 from "../../images/blog/blog-1-3.jpg";
import blog4 from "../../images/blog/blog-1-4.jpg";
import blog5 from "../../images/blog/blog-1-5.jpg";
import blog6 from "../../images/blog/blog-1-6.jpg";


const Blog = () => {
    return(
        <section className="blog-page">
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="blog-one__single">
                            <div className="blog-one__img">
                                <img src= { blog1 } alt=""/>
                                <div className="blog-one__date">
                                    <p>26 oct</p>
                                </div>
                                <a href="blog-details.html">
                                    <span className="news-one__plus"></span>
                                </a>
                            </div>
                            <div className="blog-one__content">
                                <ul className="list-unstyled blog-one__meta">
                                    <li><a href="blog-details.html"><i className="far fa-user-circle"></i> Admin</a></li>
                                    <li><a href="blog-details.html"><i className="far fa-comments"></i> 2 Comments</a>
                                    </li>
                                </ul>
                                <h3 className="blog-one__title">
                                    <a href="blog-details.html">Start a Fundraiser for
                                        Yourself</a>
                                </h3>
                                <p className="blog-one__text">Aellentesque porttitor lacus quis enim varius sed efficitur...
                                </p>
                                <div className="blog-one__bottom">
                                    <a href="blog-details.html" className="blog-one__btn">Read More</a>
                                    <a href="blog-details.html" className="blog-one__arrow"><span
                                            className="icon-right-arrow"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="blog-one__single">
                            <div className="blog-one__img">
                                <img src={ blog2 } alt=""/>
                                <div className="blog-one__date">
                                    <p>26 oct</p>
                                </div>
                                <a href="blog-details.html">
                                    <span className="news-one__plus"></span>
                                </a>
                            </div>
                            <div className="blog-one__content">
                                <ul className="list-unstyled blog-one__meta">
                                    <li><a href="blog-details.html"><i className="far fa-user-circle"></i> Admin</a></li>
                                    <li><a href="blog-details.html"><i className="far fa-comments"></i> 2 Comments</a>
                                    </li>
                                </ul>
                                <h3 className="blog-one__title">
                                    <a href="blog-details.html">Our donation is hope for poor childrens</a>
                                </h3>
                                <p className="blog-one__text">Aellentesque porttitor lacus quis enim varius sed efficitur...
                                </p>
                                <div className="blog-one__bottom">
                                    <a href="blog-details.html" className="blog-one__btn">Read More</a>
                                    <a href="blog-details.html" className="blog-one__arrow"><span
                                            className="icon-right-arrow"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="blog-one__single">
                            <div className="blog-one__img">
                                <img src={ blog3 } alt=""/>
                                <div className="blog-one__date">
                                    <p>26 oct</p>
                                </div>
                                <a href="blog-details.html">
                                    <span className="news-one__plus"></span>
                                </a>
                            </div>
                            <div className="blog-one__content">
                                <ul className="list-unstyled blog-one__meta">
                                    <li><a href="blog-details.html"><i className="far fa-user-circle"></i> Admin</a></li>
                                    <li><a href="blog-details.html"><i className="far fa-comments"></i> 2 Comments</a>
                                    </li>
                                </ul>
                                <h3 className="blog-one__title">
                                    <a href="blog-details.html">Education for Poor Children</a>
                                </h3>
                                <p className="blog-one__text">Aellentesque porttitor lacus quis enim varius sed efficitur...
                                </p>
                                <div className="blog-one__bottom">
                                    <a href="blog-details.html" className="blog-one__btn">Read More</a>
                                    <a href="blog-details.html" className="blog-one__arrow"><span
                                            className="icon-right-arrow"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="blog-one__single">
                            <div className="blog-one__img">
                                <img src={ blog4 } alt=""/>
                                <div className="blog-one__date">
                                    <p>26 oct</p>
                                </div>
                                <a href="blog-details.html">
                                    <span className="news-one__plus"></span>
                                </a>
                            </div>
                            <div className="blog-one__content">
                                <ul className="list-unstyled blog-one__meta">
                                    <li><a href="blog-details.html"><i className="far fa-user-circle"></i> Admin</a></li>
                                    <li><a href="blog-details.html"><i className="far fa-comments"></i> 2 Comments</a>
                                    </li>
                                </ul>
                                <h3 className="blog-one__title">
                                    <a href="blog-details.html">Promoting The Rights of Children</a>
                                </h3>
                                <p className="blog-one__text">Aellentesque porttitor lacus quis enim varius sed efficitur...
                                </p>
                                <div className="blog-one__bottom">
                                    <a href="blog-details.html" className="blog-one__btn">Read More</a>
                                    <a href="blog-details.html" className="blog-one__arrow"><span
                                            className="icon-right-arrow"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="blog-one__single">
                            <div className="blog-one__img">
                                <img src={ blog5 } alt=""/>
                                <div className="blog-one__date">
                                    <p>26 oct</p>
                                </div>
                                <a href="blog-details.html">
                                    <span className="news-one__plus"></span>
                                </a>
                            </div>
                            <div className="blog-one__content">
                                <ul className="list-unstyled blog-one__meta">
                                    <li><a href="blog-details.html"><i className="far fa-user-circle"></i> Admin</a></li>
                                    <li><a href="blog-details.html"><i className="far fa-comments"></i> 2 Comments</a>
                                    </li>
                                </ul>
                                <h3 className="blog-one__title">
                                    <a href="blog-details.html">Fundrising for Early Childhood Rise</a>
                                </h3>
                                <p className="blog-one__text">Aellentesque porttitor lacus quis enim varius sed efficitur...
                                </p>
                                <div className="blog-one__bottom">
                                    <a href="blog-details.html" className="blog-one__btn">Read More</a>
                                    <a href="blog-details.html" className="blog-one__arrow"><span
                                            className="icon-right-arrow"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="blog-one__single">
                            <div className="blog-one__img">
                                <img src={ blog6 } alt=""/>
                                <div className="blog-one__date">
                                    <p>26 oct</p>
                                </div>
                                <a href="blog-details.html">
                                    <span className="news-one__plus"></span>
                                </a>
                            </div>
                            <div className="blog-one__content">
                                <ul className="list-unstyled blog-one__meta">
                                    <li><a href="blog-details.html"><i className="far fa-user-circle"></i> Admin</a></li>
                                    <li><a href="blog-details.html"><i className="far fa-comments"></i> 2 Comments</a>
                                    </li>
                                </ul>
                                <h3 className="blog-one__title">
                                    <a href="blog-details.html">School Counseling for Children</a>
                                </h3>
                                <p className="blog-one__text">Aellentesque porttitor lacus quis enim varius sed efficitur...
                                </p>
                                <div className="blog-one__bottom">
                                    <a href="blog-details.html" className="blog-one__btn">Read More</a>
                                    <a href="blog-details.html" className="blog-one__arrow"><span
                                            className="icon-right-arrow"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Blog;