// importing photos!
import Rakesh from "../../images/team/Rakesh Ranjan.jpeg";
import Rahul from "../../images/team/Rahul Kumar Paswan.png";
// import Pankaj from "../../images/team/Pankaj Kumar Sinha.png";
import Sweeta from "../../images/team/Sweeta Singh.png";
import Sandeep from "../../images/team/Sandeep keshri.png";
import Aman from "../../images/team/Aman singh.png";
import Gaurav from "../../images/team/Gaurav Arya.png";
import Roushan from "../../images/team/Roushan datt.png";
import Abhishek from "../../images/team/Abhishek Parshar.png";
import Bimla from "../../images/team/Bimla Devi.png";

const Volunteer = () => {
    return(
        <section className="volunteers-one">
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="volunteers-one__single">
                            <div className="volunteers-one__img">
                                <img src = { Rakesh } alt=""/>
                                <div className="volunteers-one__social">
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-facebook"></i></a>
                                    <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="volunteers-one__content">
                                <h4 className="volunteers-one__name">Rakesh Ranjan</h4>
                                <p className="volunteers-one__title">Trustee</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="volunteers-one__single">
                            <div className="volunteers-one__img">
                                <img src = { Rahul } alt=""/>
                                <div className="volunteers-one__social">
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-facebook"></i></a>
                                    <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="volunteers-one__content">
                                <h4 className="volunteers-one__name">Rahul Kumar Paswan</h4>
                                <p className="volunteers-one__title">President</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="volunteers-one__single">
                            <div className="volunteers-one__img">
                                <img src = { Abhishek } alt=""/>
                                <div className="volunteers-one__social">
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-facebook"></i></a>
                                    <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="volunteers-one__content">
                                <h4 className="volunteers-one__name">Roushan Datt</h4>
                                <p className="volunteers-one__title">Secretary</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="volunteers-one__single">
                            <div className="volunteers-one__img">
                                <img src = { Pankaj } alt=""/>
                                <div className="volunteers-one__social">
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-facebook"></i></a>
                                    <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="volunteers-one__content">
                                <h4 className="volunteers-one__name">Pankaj Kumar Sinha</h4>
                                <p className="volunteers-one__title">Secretary</p>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="volunteers-one__single">
                            <div className="volunteers-one__img">
                                <img src = { Sweeta }  alt=""/>
                                <div className="volunteers-one__social">
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-facebook"></i></a>
                                    <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="volunteers-one__content">
                                <h4 className="volunteers-one__name">Sweeta Singh</h4>
                                <p className="volunteers-one__title">Special Secretary</p>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="volunteers-one__single">
                            <div className="volunteers-one__img">
                                <img src = { Sandeep } alt=""/>
                                <div className="volunteers-one__social">
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-facebook"></i></a>
                                    <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="volunteers-one__content">
                                <h4 className="volunteers-one__name">Sandeep Keshri</h4>
                                <p className="volunteers-one__title">Secretary for Financial affairs</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="volunteers-one__single">
                            <div className="volunteers-one__img">
                                <img src = { Aman } alt=""/>
                                <div className="volunteers-one__social">
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-facebook"></i></a>
                                    <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="volunteers-one__content">
                                <h4 className="volunteers-one__name">Aman Singh</h4>
                                <p className="volunteers-one__title">Secretary for internal affairs</p>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="volunteers-one__single">
                            <div className="volunteers-one__img">
                                <img src = { Gaurav } alt=""/>
                                <div className="volunteers-one__social">
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-facebook"></i></a>
                                    <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="volunteers-one__content">
                                <h4 className="volunteers-one__name">Gaurav Arya</h4>
                                <p className="volunteers-one__title">Member</p>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="volunteers-one__single">
                            <div className="volunteers-one__img">
                                <img src = { Roushan } alt=""/>
                                <div className="volunteers-one__social">
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-facebook"></i></a>
                                    <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="volunteers-one__content">
                                <h4 className="volunteers-one__name">Abhishek Parshar</h4>
                                <p className="volunteers-one__title">Administrator</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="volunteers-one__single">
                            <div className="volunteers-one__img">
                                <img src = { Bimla } alt=""/>
                                <div className="volunteers-one__social">
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-facebook"></i></a>
                                    <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="volunteers-one__content">
                                <h4 className="volunteers-one__name">Bimla Devi</h4>
                                <p className="volunteers-one__title">Member</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Volunteer;