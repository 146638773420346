const Contact = () => {
    return(
        <section className="contact-page">
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-5">
                        <div className="contact-page__left">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">Contact with us</span>
                                <h2 className="section-title__title">Get in Touch With us</h2>
                            </div>
                            <p className="contact-page__text">Aliq is notm hendr erit a augue insu image pellen tes que id
                                erat quis sollicitud. Lorem ipsum is simply free text dolor sit amet, consectetur am
                                blandit.</p>
                            <div className="contact-page__social">
                                <a href="#"><i className="fab fa-twitter"></i></a>
                                <a href="#"><i className="fab fa-facebook"></i></a>
                                <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                <a href="#"><i className="fab fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-7">
                        <div className="contact-page__right">
                            <form action="assets/inc/sendemail.php" className="comment-one__form contact-form-validated"
                                novalidate="novalidate">
                                <div className="row">
                                    <div className="col-xl-6">
                                        <div className="comment-form__input-box">
                                            <input type="text" placeholder="Your Name" name="name"/>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="comment-form__input-box">
                                            <input type="email" placeholder="Email Address" name="email"/>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="comment-form__input-box">
                                            <input type="text" placeholder="Phone Number" name="phone"/>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="comment-form__input-box">
                                            <select name="subject">
                                                <option value="">Subject</option>
                                                <option value="query about donation">Query about Donation</option>
                                                <option value="want be a volunteer">Want be a volunteer</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="comment-form__input-box text-message-box">
                                            <textarea name="message" placeholder="Write a Comment"></textarea>
                                        </div>
                                        <div className="comment-form__btn-box">
                                            <button type="submit" className="thm-btn comment-form__btn">Send us a
                                                message</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact;