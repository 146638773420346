// common
import Header from "../common/header";
import Footer from "../common/footer";
import MobileNav from "../common/MobileNav";

import Contact from "./sub-components/Contact";
import ContactInfo from "./sub-components/ContactInfo";
import Map from "./sub-components/Map";
import PageHeader from "./sub-components/PageHeader";


const ContactComponent = () => {
    return(
        <>
            <div className = "page-wrapper">
                <Header/>
                <PageHeader/>
                <Contact/>
                <ContactInfo/>
                <Map/>
                <Footer/>
            </div>
            <MobileNav/>
        </>
    )
}

export default ContactComponent;