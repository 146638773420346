import about1 from "../../images/resources/about-page-img-1.jpg";

const About = () => {
    return(
        <section className="about-page">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6">
                        <div className="about-page__left wow slideInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
                            <div className="about-page__img">
                                <img src= { about1 } alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="about-page__right">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">get to know us</span>
                                <h2 className="section-title__title">We Make Difference in their Life</h2>
                            </div>
                            <p className="about-page__text">Lorem ipsum dolor sit amet, consectetur notted adipisicing elit
                                sed do eiusmod tempor incididunt ut labore et simply free text dolore magna aliqua lonm
                                andhn.</p>
                            <ul className="about-one__points list-unstyled">
                                <li>
                                    <div className="icon">
                                        <span className="icon-confirmation"></span>
                                    </div>
                                    <div className="text">
                                        <p>Join our Team</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <span className="icon-confirmation"></span>
                                    </div>
                                    <div className="text">
                                        <p>Quick Fundraising</p>
                                    </div>
                                </li>
                            </ul>
                            <ul className="about-one__points-content list-unstyled">
                                <li>
                                    <p className="about-one__points-text">Lorem ipsum dolor sit ame ed consectetur nod.</p>
                                </li>
                                <li>
                                    <p className="about-one__points-text">Lorem ipsum dolor sit ame ed consectetur nod.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;