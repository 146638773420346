import twobox1 from "../../images/backgrounds/two-boxes-single-bg-1.jpg";
import twobox2 from "../../images/backgrounds/two-boxes-single-bg-2.jpg";

const Donate = () => {
    return(
        <section className="two-boxes">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6">
                        <div className="two-boxes__single wow slideInLeft" data-wow-delay="100ms"
                            data-wow-duration="2500ms">
                            <div className="two-boxes__single-bg"
                                style = {{ backgroundImage: 'url(' + twobox1 + ')' }}>
                            </div>
                            <div className="two-boxes__icon">
                                <span className="icon-fast-food"></span>
                            </div>
                            <div className="two-boxes__content">
                                <h3 className="two-boxes__title">Healthy Food</h3>
                                <p className="two-boxes__text">Lorem ipsum is simply free text available amet, consectetuer
                                    adipiscing elit.</p>
                                <a href="donate-now.html" className="donate-btn"> <i className="fa fa-heart"></i> Donate Now</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="two-boxes__single wow slideInRight" data-wow-delay="100ms"
                            data-wow-duration="2500ms">
                            <div className="two-boxes__single-bg"
                                style = {{ backgroundImage: 'url(' + twobox2 + ')' }}>
                            </div>
                            <div className="two-boxes__icon two-boxes__icon-clr-scondary">
                                <span className="icon-health-check"></span>
                            </div>
                            <div className="two-boxes__content">
                                <h3 className="two-boxes__title">Medical Treatment</h3>
                                <p className="two-boxes__text">Lorem ipsum is simply free text available amet, consectetuer
                                    adipiscing elit.</p>
                                <a href="donate-now.html" className="donate-btn"> <i className="fa fa-heart"></i> Donate Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Donate;