import testimonials1 from "../../images/testimonial/testimonials-1-1.jpg";
import testimonials2 from "../../images/testimonial/testimonials-1-2.jpg";
import testimonials3 from "../../images/testimonial/testimonials-1-3.jpg";

import gallery1 from "../../images/gallery/two-section-gallery-img-1.jpg";
import gallery2 from "../../images/gallery/two-section-gallery-img-2.jpg";
import gallery3 from "../../images/gallery/two-section-gallery-img-1.jpg";

import map from "../../images/shapes/testimonial-one-map.png";
import shape from "../../images/shapes/testimonial-one-shape.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

const Testimonials = () => {
    return(
        <section className="two-section">
            <div className="two-section__container">
                <div className="two-section__testimonial">
                    <div className="testimonial-one-map"
                        style = {{ backgroundImage: 'url(' + map + ')' }}></div>
                    <div className="testimonial-one-shape"
                        style = {{ backgroundImage: 'url(' + shape + ')' }}></div>
                    <h3 className="two-section__testimonial-title">What They’re Saying</h3>

                    <Swiper className="swiper-container" id="testimonials-one__thumb">
                        <div className="swiper-wrapper">
                            <SwiperSlide>
                                <div className="testimonial-one__img-holder">
                                    <img src={ testimonials1 } alt=""/>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="testimonial-one__img-holder">
                                    <img src={ testimonials2 } alt=""/>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="testimonial-one__img-holder">
                                    <img src={ testimonials3 } alt=""/>
                                </div>
                            </SwiperSlide>
                        </div>
                    </Swiper>

                    <div className="testimonials-one__main-content">
                        <Swiper className="swiper-container" id="testimonials-one__carousel">
                            <div className="swiper-wrapper">
                                <SwiperSlide>
                                    <div className="testimonial-one__conent-box">
                                        <p className="testimonial-one__text">“Aliquam erat volutpat. Suspendisse suscipit
                                            vestibulum leo a aliquam. In risus di pharetra sed felis nec, euismod
                                            faucibus velit. Fusce id laoreet nisl. Duis lacinia eleifend.”</p>
                                        <div className="testimonial-one__client-details">
                                            <h4 className="testimonial-one__client-name">- Christine Eve</h4>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="testimonial-one__conent-box">
                                        <p className="testimonial-one__text">“Aliquam erat volutpat. Suspendisse suscipit
                                            vestibulum leo a aliquam. In risus di pharetra sed felis nec, euismod
                                            faucibus velit. Fusce id laoreet nisl. Duis lacinia eleifend.”</p>
                                        <div className="testimonial-one__client-details">
                                            <h4 className="testimonial-one__client-name">- Christine Eve</h4>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="testimonial-one__conent-box">
                                        <p className="testimonial-one__text">“Aliquam erat volutpat. Suspendisse suscipit
                                            vestibulum leo a aliquam. In risus di pharetra sed felis nec, euismod
                                            faucibus velit. Fusce id laoreet nisl. Duis lacinia eleifend.”</p>
                                        <div className="testimonial-one__client-details">
                                            <h4 className="testimonial-one__client-name">- Christine Eve</h4>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </div>
                        </Swiper>
                        <div id="testimonials-one__carousel-pagination"></div>
                    </div>

                </div>

                <div className="two-section__gallery">
                    <div className="row">

                        <div className="col-xl-6 col-lg-3 col-md-6">
                            <div className="two-section__gallery-single">
                                <div className="two-section__gallery-img-inner">
                                    <img src={ gallery1 } alt=""/>
                                </div>
                                <div className="two-section__gallery-img-overly">
                                    <div className="two-section__gallery-icon-bg">
                                    </div>
                                    <a className="img-popup" href="assets/images/gallery/two-section-gallery-img-1.jpg">
                                        <span className="icon-right-arrow"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-3 col-md-6">
                            <div className="two-section__gallery-single">
                                <div className="two-section__gallery-img-inner">
                                    <img src={ gallery2 } alt=""/>
                                </div>
                                <div className="two-section__gallery-img-overly">
                                    <div className="two-section__gallery-icon-bg">
                                    </div>
                                    <a className="img-popup" href="assets/images/gallery/two-section-gallery-img-2.jpg">
                                        <span className="icon-right-arrow"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-3 col-md-6">
                            <div className="two-section__gallery-single">
                                <div className="two-section__gallery-img-inner">
                                    <img src={ gallery3 } alt=""/>
                                </div>
                                <div className="two-section__gallery-img-overly">
                                    <div className="two-section__gallery-icon-bg">
                                    </div>
                                    <a className="img-popup" href="assets/images/gallery/two-section-gallery-img-3.jpg">
                                        <span className="icon-right-arrow"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-3 col-md-6">
                            <div className="two-section__gallery-single">
                                <div className="two-section__gallery-img-inner">
                                    <img src="assets/images/gallery/two-section-gallery-img-4.jpg" alt=""/>
                                </div>
                                <div className="two-section__gallery-img-overly">
                                    <div className="two-section__gallery-icon-bg">
                                    </div>
                                    <a className="img-popup" href="assets/images/gallery/two-section-gallery-img-4.jpg">
                                        <span className="icon-right-arrow"></span>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonials;