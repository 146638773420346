import { Link } from "react-router-dom";
import pageheader from "../../images/village-kids-bg/jivi-bg-5.png";

const News = () => {
    return(
        <section className="page-header">
            <div className="page-header-bg" style={{ backgroundImage: 'url(' + pageheader + ')' }}>
            </div>
            <div className="container">
                <div className="page-header__inner">
                    <h2>Latest News</h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="/">Home</Link></li>
                        <li><span>/</span></li>
                        <li className="active">News</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default News;