import volunteer1 from "../../images/team/volunteers-1-1.jpg";
import volunteer2 from "../../images/team/volunteers-1-2.jpg";
import volunteer3 from "../../images/team/volunteers-1-3.jpg";

const Volunteers = () => {
    return(
        <section className="volunteers-one">
            <div className="container">
                <div className="section-title text-center">
                    <span className="section-title__tagline">Ready to help you</span>
                    <h2 className="section-title__title">Happy Volunteers</h2>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">
                        <div className="volunteers-one__single">
                            <div className="volunteers-one__img">
                                <img src= { volunteer1 } alt=""/>
                                <div className="volunteers-one__social">
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-facebook"></i></a>
                                    <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="volunteers-one__content">
                                <h4 className="volunteers-one__name">Sarah Albert</h4>
                                <p className="volunteers-one__title">Volunteers</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
                        <div className="volunteers-one__single">
                            <div className="volunteers-one__img">
                                <img src= { volunteer2 } alt=""/>
                                <div className="volunteers-one__social">
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-facebook"></i></a>
                                    <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="volunteers-one__content">
                                <h4 className="volunteers-one__name">Kevin Martin</h4>
                                <p className="volunteers-one__title">Volunteers</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
                        <div className="volunteers-one__single">
                            <div className="volunteers-one__img">
                                <img src= { volunteer3 } alt=""/>
                                <div className="volunteers-one__social">
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-facebook"></i></a>
                                    <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="volunteers-one__content">
                                <h4 className="volunteers-one__name">Jeesica Brown</h4>
                                <p className="volunteers-one__title">Volunteers</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Volunteers;