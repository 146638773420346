import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";

const Header = () => {
    const mobileNav = (e) => {
        e.preventDefault();
        document.getElementsByClassName("mobile-nav__wrapper")[0].classList.toggle("expanded");
        document.getElementsByTagName("body")[0].classList.toggle("locked");
        let navContent = document.getElementsByClassName("main-menu__list")[0].outerHTML;
        let mobileNavContainer = document.getElementsByClassName("mobile-nav__container")[0];
        mobileNavContainer.innerHTML = navContent;
    }

    return(
        <>
            <header className="main-header clearfix">
                <div className="main-header__top">
                    <div className="main-header__top-left">
                        <p className="main-header__top-text">Welcome to Jivi Adityodaya</p>
                        <div className="main-header__top-social">
                            <a href="#"><i className="fab fa-twitter"></i></a>
                            <a href="#"><i className="fab fa-facebook"></i></a>
                            <a href="#"><i className="fab fa-pinterest-p"></i></a>
                            <a href="#"><i className="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div className="main-header__top-right">
                        <ul className="list-unstyled main-header__top-address">
                            <li>
                                <div className="icon">
                                    <span className="icon-pin"></span>
                                </div>
                                <div className="text">
                                    <p>Jivi Adityodaya Sewa Sansthan, Sector 2, Dhurwa, Ranchi</p>
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <span className="icon-email"></span>
                                </div>
                                <div className="text">
                                    <p><a href="mailto:info@jiviadityodaya.com">info@jiviadityodaya.com</a></p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <nav className="main-menu clearfix">
                    <div className="main-menu-wrapper clearfix">
                        <div className="main-menu-wrapper__left">
                            <div className="main-menu-wrapper__logo">
                                <Link to="/"><img src= { logo } alt=""/></Link>
                            </div>
                            <div className="main-menu-wrapper__call">
                                <div className="main-menu-wrapper__call-icon">
                                    <span className="icon-call"></span>
                                </div>
                                <div className="main-menu-wrapper__call-number">
                                    <p>Call Anytime</p>
                                    <h5><a href="tel:13073330079">+ 1 (307) 333-0079</a></h5>
                                </div>
                            </div>
                        </div>
                        <div className="main-menu-wrapper__main-menu">
                            <a href="#" onClick={ mobileNav }className="mobile-nav__toggler"><i className="fa fa-bars"></i></a>
                            <ul className="main-menu__list">
                                <li><NavLink to="/">Home</NavLink></li>
                                {/* <li className="dropdown current">
                                    <a href="index.html">Home</a>
                                    <ul>
                                        <li>
                                            <a href="index.html">Home One</a>
                                        </li>
                                        <li><a href="index2.html">Home Two</a></li>
                                        <li><a href="index3.html">Home Three</a></li>
                                        <li className="dropdown">
                                            <a href="#">One Page Home</a>
                                            <ul>
                                                <li><a href="index-one-page.html">Home One</a></li>
                                                <li><a href="index2-one-page.html">Home Two</a></li>
                                                <li><a href="index3-one-page.html">Home Three</a></li>
                                            </ul>
                                        </li>
                                        <li className="dropdown">
                                            <a href="#">Header Styles</a>
                                            <ul>
                                                <li><a href="index.html">Header One</a></li>
                                                <li><a href="index2.html">Header Two</a></li>
                                                <li><a href="index3.html">Header Three</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li> */}
                                {/* <li className="dropdown">
                                    <a href="#">Pages</a>
                                    <ul>
                                        <li><a href="about.html">About</a></li>
                                        <li className="dropdown">
                                            <a href="volunteers.html">Volunteers</a>
                                            <ul>
                                                <li><a href="volunteers.html">Grid Style</a></li>
                                                <li><a href="volunteers-carousel.html">Carousel Style</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="become-volunteer.html">Become A Volunteer</a></li>
                                        <li className="dropdown">
                                            <a href="testimonials.html">Testimonials</a>
                                            <ul>
                                                <li><a href="testimonials.html">Grid Style</a></li>
                                                <li><a href="testimonials-carousel.html">Carousel Style</a></li>
                                            </ul>
                                        </li>
                                        <li className="dropdown">
                                            <a href="gallery.html">Gallery</a>
                                            <ul>
                                                <li><a href="gallery.html">Grid Style</a></li>
                                                <li><a href="gallery-carousel.html">Carousel Style</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="faq.html">FAQs</a></li>
                                        <li><a href="404.html">404 Error</a></li>
                                    </ul>
                                </li> */}
                                <li><NavLink to="/about">About</NavLink></li>
                                {/* <li className="dropdown">
                                    <a href="#">Donations</a>
                                    <ul>
                                        <li className="dropdown">
                                            <a href="donations.html">Donations</a>
                                            <ul>
                                                <li><a href="donations.html">Grid Style</a></li>
                                                <li><a href="donations-carousel.html">Carousel Style</a></li>
                                            </ul>
                                        </li>
                                        <li className="dropdown">
                                            <a href="donations-list.html">Donations List</a>
                                            <ul>
                                                <li><a href="donations-list.html">Grid Style</a></li>
                                                <li><a href="donations-list-carousel.html">Carousel Style</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="donate-now.html">Donate Now</a></li>
                                        <li><a href="differently-abled-kids.html">Differently Abled Kids</a></li>
                                        <li><a href="help-child-cancer.html">Help Child Cancer</a></li>
                                        <li><a href="clean-pure-water.html">Clean Pure Water</a></li>
                                        <li><a href="give-them-education.html">Give them Education</a></li>
                                        <li><a href="start-a-fundraising.html">Start a Fundraising</a></li>
                                    </ul>
                                </li> */}
                                {/* <li className="dropdown">
                                    <a href="#">Events</a>
                                    <ul>
                                        <li className="dropdown">
                                            <a href="events.html">Events</a>
                                            <ul>
                                                <li><a href="events.html">Grid Style</a></li>
                                                <li><a href="events-carousel.html">Carousel Style</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="event-details.html">Event Details</a></li>
                                    </ul>
                                </li> */}
                                <li><NavLink to="/news">News</NavLink></li>
                                {/* <li className="dropdown">
                                    <a href="#">Blog</a>
                                    <ul>
                                        <li className="dropdown">
                                            <a href="blog.html">Blog</a>
                                            <ul>
                                                <li><a href="blog.html">Grid Style</a></li>
                                                <li><a href="blog-carousel.html">Carousel Style</a></li>
                                            </ul>
                                        </li>
                                        <li className="dropdown">
                                            <a href="blog-sidebar.html">Blog Sidebar</a>
                                            <ul>
                                                <li><a href="blog-sidebar-left.html">Left Sidebar</a></li>
                                                <li><a href="blog-sidebar.html">Right Sidebar</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="blog-details.html">Blog Details</a></li>
                                    </ul>
                                </li> */}
                                <li><Link to="/contact">Contact</Link></li>
                                <li><NavLink to="/login">Login</NavLink></li>
                                <li><NavLink to="/team">Team</NavLink></li>
                            </ul>
                        </div>
                        <div className="main-menu-wrapper__right">
                            <div className="main-menu-wrapper__search-cat">
                                {/* <a href="#" className="main-menu-wrapper__search search-toggler icon-magnifying-glass"></a> */}
                                <a href="login" className="main-menu-wrapper__cart icon-avatar mini-cart__toggler"></a>
                                <a href="donate-now.html" className="donate-btn main-menu-wrapper__btn"> <i className="fa fa-heart"></i>
                                Donate Now</a>
                            </div>
                            
                        </div>
                    </div>
                </nav>
            </header>

            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
        </>
    )
}

export default Header;