import cause1 from "../../images/causes/causes-1-1.jpg";
import cause2 from "../../images/causes/causes-1-2.jpg";
import cause3 from "../../images/causes/causes-1-3.jpg";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Cause = () => {
    return(
        <section className="causes-one">
            <div className="container">
                <div className="section-title text-left">
                    <span className="section-title__tagline">Help the people</span>
                    <h2 className="section-title__title">Popular Causes</h2>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <OwlCarousel className="causes-one__carousel owl-theme">
                            
                            <div className="causes-one__single">
                                <div className="causes-one__img">
                                    <img src= { cause1 } alt=""/>
                                    <div className="causes-one__category">
                                        <span>education</span>
                                    </div>
                                </div>
                                <div className="causes-one__content-box">
                                    <div className="causes-one__content">
                                        <h3 className="causes-one__title">
                                            <a href="give-them-education.html">Give African Child a Good Education</a>
                                        </h3>
                                        <p className="causes-one__text">Aellentesque porttitor lacus quis enim varius sed
                                            efficitur...</p>
                                    </div>
                                    <div className="causes-one__progress causes-one__progress-2">
                                        <div className="bar">
                                            <div className="bar-inner count-bar" data-percent="36%">
                                                <div className="count-text">36%</div>
                                            </div>
                                        </div>
                                        <div className="causes-one__goals">
                                            <p><span>$25,270</span> Raised</p>
                                            <p><span>$30,000</span> Goal</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="causes-one__single causes-one__single-2">
                                <div className="causes-one__img">
                                    <img src= { cause2 } alt=""/>
                                    <div className="causes-one__category">
                                        <span>medical</span>
                                    </div>
                                </div>
                                <div className="causes-one__content-box">
                                    <div className="causes-one__content">
                                        <h3 className="causes-one__title">
                                            <a href="start-a-fundraising.html">A Little Help can Heal their Pains</a>
                                        </h3>
                                        <p className="causes-one__text">Aellentesque porttitor lacus quis enim varius sed
                                            efficitur...</p>
                                    </div>
                                    <div className="causes-one__progress">
                                        <div className="bar">
                                            <div className="bar-inner count-bar" data-percent="36%">
                                                <div className="count-text">36%</div>
                                            </div>
                                        </div>
                                        <div className="causes-one__goals">
                                            <p><span>$25,270</span> Raised</p>
                                            <p><span>$30,000</span> Goal</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="causes-one__single causes-one__single-3">
                                <div className="causes-one__img">
                                    <img src= { cause3 } alt=""/>
                                    <div className="causes-one__category">
                                        <span>Food</span>
                                    </div>
                                </div>
                                <div className="causes-one__content-box">
                                    <div className="causes-one__content">
                                        <h3 className="causes-one__title">
                                            <a href="clean-pure-water.html">Raise Fund for Clean & Healthy Food</a>
                                        </h3>
                                        <p className="causes-one__text">Aellentesque porttitor lacus quis enim varius sed
                                            efficitur...</p>
                                    </div>
                                    <div className="causes-one__progress">
                                        <div className="bar">
                                            <div className="bar-inner count-bar" data-percent="36%">
                                                <div className="count-text">36%</div>
                                            </div>
                                        </div>
                                        <div className="causes-one__goals">
                                            <p><span>$25,270</span> Raised</p>
                                            <p><span>$30,000</span> Goal</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="causes-one__single">
                                <div className="causes-one__img">
                                    <img src= { cause1 } alt=""/>
                                    <div className="causes-one__category">
                                        <span>education</span>
                                    </div>
                                </div>
                                <div className="causes-one__content-box">
                                    <div className="causes-one__content">
                                        <h3 className="causes-one__title">
                                            <a href="give-them-education.html">Give African Child a Good Education</a>
                                        </h3>
                                        <p className="causes-one__text">Aellentesque porttitor lacus quis enim varius sed
                                            efficitur...</p>
                                    </div>
                                    <div className="causes-one__progress causes-one__progress-2">
                                        <div className="bar">
                                            <div className="bar-inner count-bar" data-percent="36%">
                                                <div className="count-text">36%</div>
                                            </div>
                                        </div>
                                        <div className="causes-one__goals">
                                            <p><span>$25,270</span> Raised</p>
                                            <p><span>$30,000</span> Goal</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="causes-one__single causes-one__single-2">
                                <div className="causes-one__img">
                                    <img src= { cause2 } alt=""/>
                                    <div className="causes-one__category">
                                        <span>medical</span>
                                    </div>
                                </div>
                                <div className="causes-one__content-box">
                                    <div className="causes-one__content">
                                        <h3 className="causes-one__title">
                                            <a href="start-a-fundraising.html">A Little Help can Heal their Pains</a>
                                        </h3>
                                        <p className="causes-one__text">Aellentesque porttitor lacus quis enim varius sed
                                            efficitur...</p>
                                    </div>
                                    <div className="causes-one__progress">
                                        <div className="bar">
                                            <div className="bar-inner count-bar" data-percent="36%">
                                                <div className="count-text">36%</div>
                                            </div>
                                        </div>
                                        <div className="causes-one__goals">
                                            <p><span>$25,270</span> Raised</p>
                                            <p><span>$30,000</span> Goal</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="causes-one__single causes-one__single-3">
                                <div className="causes-one__img">
                                    <img src= { cause3 } alt=""/>
                                    <div className="causes-one__category">
                                        <span>Food</span>
                                    </div>
                                </div>
                                <div className="causes-one__content-box">
                                    <div className="causes-one__content">
                                        <h3 className="causes-one__title">
                                            <a href="clean-pure-water.html">Raise Fund for Clean & Healthy Food</a>
                                        </h3>
                                        <p className="causes-one__text">Aellentesque porttitor lacus quis enim varius sed
                                            efficitur...</p>
                                    </div>
                                    <div className="causes-one__progress">
                                        <div className="bar">
                                            <div className="bar-inner count-bar" data-percent="36%">
                                                <div className="count-text">36%</div>
                                            </div>
                                        </div>
                                        <div className="causes-one__goals">
                                            <p><span>$25,270</span> Raised</p>
                                            <p><span>$30,000</span> Goal</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="causes-one__single">
                                <div className="causes-one__img">
                                    <img src= { cause1 } alt=""/>
                                    <div className="causes-one__category">
                                        <span>education</span>
                                    </div>
                                </div>
                                <div className="causes-one__content-box">
                                    <div className="causes-one__content">
                                        <h3 className="causes-one__title">
                                            <a href="give-them-education.html">Give African Child a Good Education</a>
                                        </h3>
                                        <p className="causes-one__text">Aellentesque porttitor lacus quis enim varius sed
                                            efficitur...</p>
                                    </div>
                                    <div className="causes-one__progress causes-one__progress-2">
                                        <div className="bar">
                                            <div className="bar-inner count-bar" data-percent="36%">
                                                <div className="count-text">36%</div>
                                            </div>
                                        </div>
                                        <div className="causes-one__goals">
                                            <p><span>$25,270</span> Raised</p>
                                            <p><span>$30,000</span> Goal</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="causes-one__single causes-one__single-2">
                                <div className="causes-one__img">
                                    <img src= { cause2 } alt=""/>
                                    <div className="causes-one__category">
                                        <span>medical</span>
                                    </div>
                                </div>
                                <div className="causes-one__content-box">
                                    <div className="causes-one__content">
                                        <h3 className="causes-one__title">
                                            <a href="start-a-fundraising.html">A Little Help can Heal their Pains</a>
                                        </h3>
                                        <p className="causes-one__text">Aellentesque porttitor lacus quis enim varius sed
                                            efficitur...</p>
                                    </div>
                                    <div className="causes-one__progress">
                                        <div className="bar">
                                            <div className="bar-inner count-bar" data-percent="36%">
                                                <div className="count-text">36%</div>
                                            </div>
                                        </div>
                                        <div className="causes-one__goals">
                                            <p><span>$25,270</span> Raised</p>
                                            <p><span>$30,000</span> Goal</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="causes-one__single causes-one__single-3">
                                <div className="causes-one__img">
                                    <img src={ cause3 } alt=""/>
                                    <div className="causes-one__category">
                                        <span>Food</span>
                                    </div>
                                </div>
                                <div className="causes-one__content-box">
                                    <div className="causes-one__content">
                                        <h3 className="causes-one__title">
                                            <a href="clean-pure-water.html">Raise Fund for Clean & Healthy Food</a>
                                        </h3>
                                        <p className="causes-one__text">Aellentesque porttitor lacus quis enim varius sed
                                            efficitur...</p>
                                    </div>
                                    <div className="causes-one__progress">
                                        <div className="bar">
                                            <div className="bar-inner count-bar" data-percent="36%">
                                                <div className="count-text">36%</div>
                                            </div>
                                        </div>
                                        <div className="causes-one__goals">
                                            <p><span>$25,270</span> Raised</p>
                                            <p><span>$30,000</span> Goal</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="causes-one__single">
                                <div className="causes-one__img">
                                    <img src= { cause1 } alt=""/>
                                    <div className="causes-one__category">
                                        <span>education</span>
                                    </div>
                                </div>
                                <div className="causes-one__content-box">
                                    <div className="causes-one__content">
                                        <h3 className="causes-one__title">
                                            <a href="give-them-education.html">Give African Child a Good Education</a>
                                        </h3>
                                        <p className="causes-one__text">Aellentesque porttitor lacus quis enim varius sed
                                            efficitur...</p>
                                    </div>
                                    <div className="causes-one__progress causes-one__progress-2">
                                        <div className="bar">
                                            <div className="bar-inner count-bar" data-percent="36%">
                                                <div className="count-text">36%</div>
                                            </div>
                                        </div>
                                        <div className="causes-one__goals">
                                            <p><span>$25,270</span> Raised</p>
                                            <p><span>$30,000</span> Goal</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="causes-one__single causes-one__single-2">
                                <div className="causes-one__img">
                                    <img src={ cause2 } alt=""/>
                                    <div className="causes-one__category">
                                        <span>medical</span>
                                    </div>
                                </div>
                                <div className="causes-one__content-box">
                                    <div className="causes-one__content">
                                        <h3 className="causes-one__title">
                                            <a href="start-a-fundraising.html">A Little Help can Heal their Pains</a>
                                        </h3>
                                        <p className="causes-one__text">Aellentesque porttitor lacus quis enim varius sed
                                            efficitur...</p>
                                    </div>
                                    <div className="causes-one__progress">
                                        <div className="bar">
                                            <div className="bar-inner count-bar" data-percent="36%">
                                                <div className="count-text">36%</div>
                                            </div>
                                        </div>
                                        <div className="causes-one__goals">
                                            <p><span>$25,270</span> Raised</p>
                                            <p><span>$30,000</span> Goal</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="causes-one__single causes-one__single-3">
                                <div className="causes-one__img">
                                    <img src={ cause3 } alt=""/>
                                    <div className="causes-one__category">
                                        <span>Food</span>
                                    </div>
                                </div>
                                <div className="causes-one__content-box">
                                    <div className="causes-one__content">
                                        <h3 className="causes-one__title">
                                            <a href="clean-pure-water.html">Raise Fund for Clean & Healthy Food</a>
                                        </h3>
                                        <p className="causes-one__text">Aellentesque porttitor lacus quis enim varius sed
                                            efficitur...</p>
                                    </div>
                                    <div className="causes-one__progress">
                                        <div className="bar">
                                            <div className="bar-inner count-bar" data-percent="36%">
                                                <div className="count-text">36%</div>
                                            </div>
                                        </div>
                                        <div className="causes-one__goals">
                                            <p><span>$25,270</span> Raised</p>
                                            <p><span>$30,000</span> Goal</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Cause;