import brand1 from "../../images/brand/brand-1-1.png";
import brand2 from "../../images/brand/brand-1-2.png";
import brand3 from "../../images/brand/brand-1-3.png";
import brand4 from "../../images/brand/brand-1-4.png";
import brand5 from "../../images/brand/brand-1-5.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

const Brand = () => {
    return(
        <section className="brand-one">
            <div className="container">
                <Swiper className="thm-swiper__slider swiper-container" spaceBetween={50}
                    slidesPerView={5}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}>
                    <div className="swiper-wrapper">
                        <SwiperSlide>
                            <img src= { brand1 } alt=""/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src= { brand2 } alt=""/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={ brand3 } alt=""/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={ brand4 } alt=""/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src= { brand5 } alt=""/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src= { brand1 } alt=""/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src= { brand2 } alt=""/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src= { brand3 } alt=""/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src= { brand4 } alt=""/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src= { brand5 } alt=""/>
                        </SwiperSlide>
                    </div>
                </Swiper>
            </div>
        </section>
    )
}

export default Brand;