import tab1 from "../../images/resources/tab-info-1-1.jpg";
import tab2 from "../../images/resources/tab-info-1-2.jpg";
import tab3 from "../../images/resources/tab-featured-cause.jpg";

import workshape from "../../images/shapes/quality-work--shape.png";
import tabcontent from "../../images/backgrounds/tab-content-video-bg.jpg";

const Work = () => {
    return(
        <section className="quality-work">
            <div className="quality-work-shape"
                style = {{ backgroundImage: 'url(' + workshape + ')' }}></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="quality-work__tab-box tabs-box">
                            <ul className="tab-buttons clearfix list-unstyled">
                                <li data-tab="#approach" className="tab-btn active-btn"><span>Our Approach</span></li>
                                <li data-tab="#inspiration" className="tab-btn"><span>Full Inspiration</span></li>
                                <li data-tab="#difference" className="tab-btn"><span>Make a Difference</span></li>
                            </ul>
                            <div className="tabs-content">
                                <div className="tab active-tab animated fadeInUp" id="approach">
                                    <div className="tab-content__inner">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="tab-content__inner-left">
                                                    <ul className="list-unstyled tab-content__inner-list">
                                                        <li>
                                                            <div className="tab-content__icon">
                                                                <span className="icon-tick"></span>
                                                            </div>
                                                            <div className="tab-content__desc">
                                                                <h4 className="tab-content__title">Get Inspire and Help</h4>
                                                                <p className="tab-content__text">Lorem Ipsum nibh vel velit
                                                                    auctor aliqu. Aenean sollic tudin, lorem is simply
                                                                    man made many people recover them free text quis
                                                                    bibendum.</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div
                                                                className="tab-content__icon tab-content__icon-clr-seacondary">
                                                                <span className="icon-tick"></span>
                                                            </div>
                                                            <div className="tab-content__desc">
                                                                <h4 className="tab-content__title">Become a Volunteer</h4>
                                                                <p className="tab-content__text">Lorem Ipsum nibh vel velit
                                                                    auctor aliqu. Aenean sollic tudin, lorem is simply
                                                                    man made many people recover them free text quis
                                                                    bibendum.</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="tab-content__inner-right ">
                                                    <div className="tab-content__video-link">
                                                        <div className="tab-content-video-bg"
                                                            style = {{ backgroundImage: 'url(' + tabcontent + ')' }}>
                                                        </div>
                                                        <a href="https://www.youtube.com/watch?v=Get7rqXYrbQ"
                                                            className="video-popup">
                                                            <div className="tab-content__video-icon">
                                                                <span className="fa fa-play"></span>
                                                                <i className="ripple"></i>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab  animated fadeInUp" id="inspiration">
                                    <div className="tab-content__inner">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="tab-content__inner-left">
                                                    <h3 className="tab-content__title tab-content__title--big">Our Values
                                                    </h3>
                                                    <p className="tab-content__summery">Lorem Ipsum nibh vel velit auctor
                                                        aliqu. Aenean sollic tudin,
                                                        lorem is simply man made many people recover we want to make
                                                        sure that people can get food, education, treatment and
                                                        accommodation.</p>
                                                    <div className="row">
                                                        <div className="col-sm-6 tab-content__image">
                                                            <img src= { tab1 } alt=""/>
                                                        </div>
                                                        <div className="col-sm-6 tab-content__image">
                                                            <img src={ tab2 } alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="tab-content__inner-right  ">
                                                    <div className="faq-page__single">
                                                        <div className="accrodion-grp faq-one-accrodion"
                                                            data-grp-name="faq-one-accrodion-1">
                                                            <div className="accrodion">
                                                                <div className="accrodion-title">
                                                                    <h4>Why is it important to support them</h4>
                                                                </div>
                                                                <div className="accrodion-content">
                                                                    <div className="inner">
                                                                        <p>There are many variations of passages the
                                                                            majority have suffered
                                                                            alteration in some fo injected humour, or
                                                                            randomised words believable.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accrodion active">
                                                                <div className="accrodion-title">
                                                                    <h4>Start a fundraiser for yourself</h4>
                                                                </div>
                                                                <div className="accrodion-content">
                                                                    <div className="inner">
                                                                        <p>There are many variations of passages the
                                                                            majority have suffered
                                                                            alteration in some fo injected humour, or
                                                                            randomised words believable.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accrodion last-chiled">
                                                                <div className="accrodion-title">
                                                                    <h4>Promoting the rights of the children</h4>
                                                                </div>
                                                                <div className="accrodion-content">
                                                                    <div className="inner">
                                                                        <p>There are many variations of passages the
                                                                            majority have suffered
                                                                            alteration in some fo injected humour, or
                                                                            randomised words believable.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab  animated fadeInUp" id="difference">
                                    <div className="tab-content__inner">
                                        <div className="row">
                                            <div className="col-xl-7 col-lg-7">
                                                <div className="tab-content__inner-left">
                                                    <div className="tab-content__difference">

                                                        <h3 className="tab-content__title tab-content__title--big">
                                                            Raise Fund for Clean & Healthy Food
                                                        </h3>
                                                        <p className="tab-content__summery">
                                                            Lorem Ipsum nibh vel velit auctor aliqu. Aenean sollic
                                                            tudin,
                                                            lorem is simply man made many people recover we want to make
                                                            sure that people can get food, education, treatment and
                                                            accommodation for everyone who is living in poverty. them
                                                            free
                                                            text quis bibendum.
                                                        </p>
                                                        <div className="featured-campaigns__progress">
                                                            <div className="bar">
                                                                <div className="bar-inner count-bar" data-percent="76%">
                                                                    <div className="count-text">76%</div>
                                                                </div>
                                                            </div>
                                                            <div className="featured-campaigns__goals">
                                                                <p><span>$25,270</span> Raised</p>
                                                                <p><span>$30,000</span> Goal</p>
                                                            </div>
                                                        </div>
                                                        <a href="donate-now.html" className="donate-btn"> <i
                                                                className="fa fa-heart"></i>
                                                            Donate Now</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-5 col-lg-5">
                                                <div className="tab-content__inner-right ">
                                                    <div className="tab-content__image">
                                                        <img src={ tab3 }
                                                            alt="tab causes"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Work;