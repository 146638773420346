const Counter = () => {
    return(
        <section className="counter-one">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="counter-one__inner">
                            <ul className="list-unstyled counter-one__list">
                                <li className="counter-one__single wow fadeInUp" data-wow-delay="100ms">
                                    <div className="counter-one__icon">
                                        <span className="icon-campaign"></span>
                                    </div>
                                    <h3 className="odometer" data-count="4850">00</h3>
                                    <p className="counter-one__text">Total Campaigns</p>
                                </li>
                                <li className="counter-one__single wow fadeInUp" data-wow-delay="200ms">
                                    <div className="counter-one__icon counter-one__icon-clr-primary">
                                        <span className="icon-budget"></span>
                                    </div>
                                    <h3 className="odometer" data-count="3456">00</h3>
                                    <p className="counter-one__text">Raised Funds</p>
                                </li>
                                <li className="counter-one__single wow fadeInUp" data-wow-delay="300ms">
                                    <div className="counter-one__icon counter-one__icon-clr-extra">
                                        <span className="icon-social-campaign"></span>
                                    </div>
                                    <h3 className="odometer" data-count="480">00</h3>
                                    <p className="counter-one__text">Satisfied Donors</p>
                                </li>
                                <li className="counter-one__single wow fadeInUp" data-wow-delay="400ms">
                                    <div className="counter-one__icon counter-one__icon-clr-secondary">
                                        <span className="icon-help"></span>
                                    </div>
                                    <h3 className="odometer" data-count="2060">00</h3>
                                    <p className="counter-one__text">Happy Volunteers</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Counter;