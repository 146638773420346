import ctashape from "../../images/shapes/cta-one-shape.png";

const CTA = () => {
    return(
        <section className="cta-one">
            <div className="cta-one-shape" style={{ backgroundImage: 'url(' + ctashape + ')' }}></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="cta-one__inner">
                            <div className="cta-one__left">
                                <div className="cta-one__icon">
                                    <span className="icon-support"></span>
                                </div>
                                <h2 className="cta-one__title">Let’s Make a Difference in <br/> the Lives of Others</h2>
                            </div>
                            <div className="cta-one__right">
                                <a href="become-volunteer.html" className="thm-btn cta-one__btn">become a volunteer</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CTA;