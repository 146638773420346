// Common components
import Header from "../common/header";
import Footer from "../common/footer";
import Scroll from "../common/ScrollTop";
import MobileNav from "../common/MobileNav";

// sub components!
import Slider from "./sub-components/Slider";
import Believe from "./sub-components/Believe";
import Welcome from "./sub-components/Welcome";
import Causes from "./sub-components/Cause";
import Donate from "./sub-components/Donate";
import Brand from "./sub-components/Brand";
import Testimonials from "./sub-components/Testimonials";
import Fundraising from "./sub-components/Fundraising";
import Work from "./sub-components/Work";
import Counter from "./sub-components/Counter";
import Blog from "./sub-components/Blog";
import Feature from "./sub-components/Feature";
import Map from "./sub-components/Map";
import Search from "./sub-components/Search";
import Aditya from "./sub-components/aditya";

const HomeComponent = () => {
    return(
        <>
            <div className="page-wrapper">
                <Header/>
                <Slider/>
                <Believe/>
                <Welcome/>
                <Causes/>
                <Aditya/>
                <Donate/>
                <Brand/>
                <Testimonials/>
                <Fundraising/>
                <Work/>
                <Counter/>
                <Blog/>
                <Feature/>
                <Map/>
                <Footer/>
            </div>   
            <MobileNav/>
            <Search/>
            <Scroll/>
        </>
    )
}

export default HomeComponent;