const Feature = () => {
    return(
        <section className="feature-one">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 wow slideInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
                        <div className="feature-one__single">
                            <div className="feature-one__icon">
                                <span className="icon-sponsor"></span>
                            </div>
                            <div className="feature-one__content">
                                <h4 className="feature-one__title">Sponsor an Entire Project</h4>
                                <p className="feature-one__text">There are many variations of available but the majority
                                    have suffered alteration.</p>
                            </div>
                            <a href="donations.html" className="feature-one__btn">More</a>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 wow slideInRight" data-wow-delay="100ms" data-wow-duration="2500ms">
                        <div className="feature-one__single feature-one__single-2">
                            <div className="feature-one__icon">
                                <span className="icon-solidarity"></span>
                            </div>
                            <div className="feature-one__content">
                                <h4 className="feature-one__title">Look at the Popular Causes</h4>
                                <p className="feature-one__text">There are many variations of available but the majority
                                    have suffered alteration.</p>
                            </div>
                            <a href="donations.html" className="feature-one__btn">More</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Feature;