import background from "../images/backgrounds/site-footer-bg.jpg";

const Footer = () => {
    return(
        <footer className="site-footer">
            <div className="site-footer-bg" style = {{ backgroundImage: 'url(' + background + ')' }}>
            </div>
            <div className="site-footer__top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms">
                            <div className="footer-widget__column footer-widget__about">
                                <div className="footer-widget__about-text-box">
                                    <p className="footer-widget__about-text">Your Donations can Change their Daily Life
                                        Style</p>
                                </div>
                                <a href="donate-now.html" className="donate-btn footer-donate__btn"> <i
                                        className="fa fa-heart"></i>
                                    Donate Now</a>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                            <div className="footer-widget__column footer-widget__links clearfix">
                                <h3 className="footer-widget__title">Links</h3>
                                <ul className="footer-widget__links-list list-unstyled clearfix">
                                    <li><a href="donations.html">Recent Causes</a></li>
                                    <li><a href="about.html">About us</a></li>
                                    <li><a href="events.html">New Campaign</a></li>
                                    <li><a href="about.html">Site Map</a></li>
                                    <li><a href="events.html">Events</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms">
                            <div className="footer-widget__column footer-widget__non-profit clearfix">
                                <h3 className="footer-widget__title">Non Profit</h3>
                                <ul className="footer-widget__non-profit-list list-unstyled clearfix">
                                    <li><a href="differently-abled-kids.html">Differently Abled Kids</a></li>
                                    <li><a href="help-child-cancer.html">Help Child Cancer</a></li>
                                    <li><a href="clean-pure-water.html">Clean Pure Water</a></li>
                                    <li><a href="give-them-education.html">Give them Education</a></li>
                                    <li><a href="start-a-fundraising.html">Start a Fundraising</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="400ms">
                            <div className="footer-widget__column footer-widget__contact clearfix">
                                <h3 className="footer-widget__title">Contact</h3>
                                <ul className="list-unstyled footer-widget__contact-list">
                                    <li>
                                        <div className="icon">
                                            <span className="icon-email"></span>
                                        </div>
                                        <div className="text">
                                            <a href="mailto:info@jiviadityodaya.comcom">info@jiviadityodaya.com</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-telephone"></span>
                                        </div>
                                        <div className="text">
                                            <a href="tel:307760608"> + 1 (307) 776-0608</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-pin"></span>
                                        </div>
                                        <div className="text">
                                            <p>Jivi Adityodaya Sewa Sansthan, Sector 2, Dhurwa, Ranchi</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-footer__bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="site-footer__bottom-inner">
                                <p className="site-footer__bottom-text">© Copyright 2022 by <a href="#">jiviadityodaya.com</a>
                                </p>
                                <div className="site-footer__social">
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-facebook"></i></a>
                                    <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;