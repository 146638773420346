import welcome1 from "../../images/aaditya.jpeg";
import welcome3 from "../../images/shapes/welcome-one-shape-1.png";

const Aditya = () => {
    return(
        <section className="welcome-one">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6">
                        <div className="welcome-one__right">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">The reason behind Jivi Adityodaya</span>
                                <h2 className="section-title__title">Aditya</h2>
                            </div>
                            <p className="welcome-one__text-1">Jivi Adityodaya is the largest global crowdfunding.</p>
                            <p className="welcome-one__text-2">Lorem ipsum dolor sit amet, consectetur notted adipisicing
                                elit sed do eiusmod tempor incididunt ut labore et simply free text dolore magna aliqua
                                lonm andhn.</p>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="welcome-one__left">
                            <div className="welcome-one__img-box wow slideInLeft" data-wow-delay="100ms"
                                data-wow-duration="2500ms">
                                <div className="welcome-one__img">
                                    <img src={ welcome1 } alt=""/>
                                </div>
                                <div className="welcome-one-shape-1">
                                    <img src={ welcome3 } alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Aditya;