import fundraising from "../../images/backgrounds/fundraishing-bg.jpg";

const Fundraising = () => {
    return(
        <section className="fundraishing">
            <div className="fundraishing-bg-box">
                <div className="fundraishing-bg jarallax" data-jarallax data-speed="0.2"
                    style = {{ backgroundImage: 'url(' + fundraising + ')' }}></div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="fundraishing__inner">
                            <p className="fundraishing__sub-title">We’re Here to Support Them</p>
                            <h2 className="fundraishing__title">Fundraising for the <span>People</span> and <br/> Causes you
                                Care About</h2>
                            <div className="fundraishing__btn-box">
                                <a href="donate-now.html" className="thm-btn fundraishing__btn">Start Donating now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Fundraising;