import testimonialbg from "../../images/backgrounds/testimonial-two-bg.png";
import testimonial1 from "../../images/testimonial/testimonials-2-1.jpg";
import testimonial2 from "../../images/testimonial/testimonials-2-2.jpg";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Testimonials = () => {
    return(
        <section className="testimonial-two testimonial-three">
            <div className="testimonial-two-bg"
                style= {{ backgroundImage: 'url(' + testimonialbg + ')' }}></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-4">
                        <div className="testimonial-two__left">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">our testimonials</span>
                                <h2 className="section-title__title">What They’re Talking About Company?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8">
                        <div className="testimonial-two__right">
                            <OwlCarousel className="testimonial-two__carousel owl-carousel owl-theme">
                                <div className="testimonial-two__single">
                                    <div className="testimonial-two__content">
                                        <div className="testimonial-two__quote">
                                            <span className="icon-quote"></span>
                                        </div>
                                        <p className="testimonial-two__text">Lorem ipsum is simply free text dolor sit amet,
                                            consect notted adipisicing elit sed do eiusmod tempor incididunt ut labore
                                            et dolore magna aliqua.</p>
                                    </div>
                                    <div className="testimonial-two__client-info">
                                        <div className="testimonial-two__client-img">
                                            <img src= { testimonial1 } alt=""/>
                                        </div>
                                        <div className="testimonial-two__client-details">
                                            <h5 className="testimonial-two__client-name">Kevin Martin</h5>
                                            <p className="testimonial-two__client-title">Customer</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-two__single">
                                    <div className="testimonial-two__content">
                                        <div className="testimonial-two__quote">
                                            <span className="icon-quote"></span>
                                        </div>
                                        <p className="testimonial-two__text">Lorem ipsum is simply free text dolor sit amet,
                                            consect notted adipisicing elit sed do eiusmod tempor incididunt ut labore
                                            et dolore magna aliqua.</p>
                                    </div>
                                    <div className="testimonial-two__client-info">
                                        <div className="testimonial-two__client-img">
                                            <img src= { testimonial2 } alt=""/>
                                        </div>
                                        <div className="testimonial-two__client-details">
                                            <h5 className="testimonial-two__client-name">Sarah Albert</h5>
                                            <p className="testimonial-two__client-title">Customer</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-two__single">
                                    <div className="testimonial-two__content">
                                        <div className="testimonial-two__quote">
                                            <span className="icon-quote"></span>
                                        </div>
                                        <p className="testimonial-two__text">Lorem ipsum is simply free text dolor sit amet,
                                            consect notted adipisicing elit sed do eiusmod tempor incididunt ut labore
                                            et dolore magna aliqua.</p>
                                    </div>
                                    <div className="testimonial-two__client-info">
                                        <div className="testimonial-two__client-img">
                                            <img src={ testimonial1 } alt=""/>
                                        </div>
                                        <div className="testimonial-two__client-details">
                                            <h5 className="testimonial-two__client-name">Kevin Martin</h5>
                                            <p className="testimonial-two__client-title">Customer</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-two__single">
                                    <div className="testimonial-two__content">
                                        <div className="testimonial-two__quote">
                                            <span className="icon-quote"></span>
                                        </div>
                                        <p className="testimonial-two__text">Lorem ipsum is simply free text dolor sit amet,
                                            consect notted adipisicing elit sed do eiusmod tempor incididunt ut labore
                                            et dolore magna aliqua.</p>
                                    </div>
                                    <div className="testimonial-two__client-info">
                                        <div className="testimonial-two__client-img">
                                            <img src= { testimonial2 } alt=""/>
                                        </div>
                                        <div className="testimonial-two__client-details">
                                            <h5 className="testimonial-two__client-name">Sarah Albert</h5>
                                            <p className="testimonial-two__client-title">Customer</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-two__single">
                                    <div className="testimonial-two__content">
                                        <div className="testimonial-two__quote">
                                            <span className="icon-quote"></span>
                                        </div>
                                        <p className="testimonial-two__text">Lorem ipsum is simply free text dolor sit amet,
                                            consect notted adipisicing elit sed do eiusmod tempor incididunt ut labore
                                            et dolore magna aliqua.</p>
                                    </div>
                                    <div className="testimonial-two__client-info">
                                        <div className="testimonial-two__client-img">
                                            <img src={ testimonial1 } alt=""/>
                                        </div>
                                        <div className="testimonial-two__client-details">
                                            <h5 className="testimonial-two__client-name">Kevin Martin</h5>
                                            <p className="testimonial-two__client-title">Customer</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-two__single">
                                    <div className="testimonial-two__content">
                                        <div className="testimonial-two__quote">
                                            <span className="icon-quote"></span>
                                        </div>
                                        <p className="testimonial-two__text">Lorem ipsum is simply free text dolor sit amet,
                                            consect notted adipisicing elit sed do eiusmod tempor incididunt ut labore
                                            et dolore magna aliqua.</p>
                                    </div>
                                    <div className="testimonial-two__client-info">
                                        <div className="testimonial-two__client-img">
                                            <img src= { testimonial2 } alt=""/>
                                        </div>
                                        <div className="testimonial-two__client-details">
                                            <h5 className="testimonial-two__client-name">Sarah Albert</h5>
                                            <p className="testimonial-two__client-title">Customer</p>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonials;