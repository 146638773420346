// Common
import Header from "../common/header";
import Footer from "../common/footer";
import MobileNav from "../common/MobileNav";

// Sub components
import LoginHeader from "./sub-components/LoginHeader";
import Login from "./sub-components/Login";

const LoginComponent = () => {
    return(
        <>
            <div className="page-wrapper">
                <Header/>
                <LoginHeader/>
                <Login/>
                <Footer/>
            </div>
            <MobileNav/>
        </>
    )
}

export default LoginComponent;