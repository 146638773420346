import { BrowserRouter, Route, Switch } from "react-router-dom";
import AboutComponent from "./components/AboutComponent/AboutComponent";
import ContactComponent from "./components/ContactComponent/ContactComponent";
import HomeComponent from "./components/HomeComponent/HomeComponent";
import NewsComponent from "./components/NewsComponent/NewsComponent";
import LoginComponent from "./components/LoginComponent/LoginComponent";
import VolunteerComponent from "./components/VolunteerComponent/VolunteerComponent";

function App() {
  return (
      <div className="App">
          <BrowserRouter>
            <Switch>
              <Route exact path = "/">
                <HomeComponent/>
              </Route>
              <Route path = "/about">
                <AboutComponent/>
              </Route>
              <Route path = "/news">
                <NewsComponent/>
              </Route>
              <Route path = "/contact">
                <ContactComponent/>
              </Route>
              <Route path = "/team">
                <VolunteerComponent/>
              </Route>
              <Route path = "/login">
                <LoginComponent/>
              </Route>
            </Switch>
          </BrowserRouter>
      </div>
  );
}

export default App;
