// common
import Mobilenav from "../common/MobileNav";
import Header from "../common/header";
import Footer from "../common/footer";
import News from "./sub-components/News";
import Blog from "./sub-components/blog";

const NewsComponent = () => {
    return(
        <>
            <div className = "page-wrapper">
                <Header/>
                <News/>
                <Blog/>
                <Footer/>
            </div>
            <Mobilenav/>
        </>
    )
}

export default NewsComponent;