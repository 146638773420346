import logo from "../images/logo.png";
import { Link } from "react-router-dom";

const Mobilenav = () => {

    const closeNav = (e) => {
        e.preventDefault();
        document.getElementsByClassName("mobile-nav__wrapper")[0].classList.toggle("expanded");
        document.getElementsByTagName("body")[0].classList.toggle("locked");
    }

    return(
           <>
                 <div className="mobile-nav__wrapper">
                    <div className="mobile-nav__overlay mobile-nav__toggler"></div>
                    <div className="mobile-nav__content">
                        <span className="mobile-nav__close mobile-nav__toggler" onClick = { closeNav }><i className="fa fa-times"></i></span>

                        <div className="logo-box">
                            <Link to = "/" aria-label="logo image"><img src = { logo }  alt="" /></Link>
                        </div>
                        
                        <div className="mobile-nav__container"></div>

                        <ul className="mobile-nav__contact list-unstyled">
                            <li>
                                <i className="fa fa-envelope"></i>
                                <a href="mailto:info@jiviadityodaya.com">info@jiviadityodaya.com</a>
                            </li>
                            <li>
                                <i className="fa fa-phone-alt"></i>
                                <a href="tel:666-888-0000">666 888 0000</a>
                            </li>
                        </ul>
                        <div className="mobile-nav__top">
                            <div className="mobile-nav__social">
                                <a href="#" className="fab fa-twitter"></a>
                                <a href="#" className="fab fa-facebook-square"></a>
                                <a href="#" className="fab fa-pinterest-p"></a>
                                <a href="#" className="fab fa-instagram"></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="search-popup">
                    <div className="search-popup__overlay search-toggler"></div>
                    <div className="search-popup__content">
                        <form action="#">
                            <label htmlFor="search" className="sr-only">search here</label>
                            <input type="text" id="search" placeholder="Search Here..." />
                            <button type="submit" aria-label="search submit" className="thm-btn">
                                <i className="icon-magnifying-glass"></i>
                            </button>
                        </form>
                    </div>
                </div>

                <a href="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></a>
           </> 
    )
}

export default Mobilenav;