const Login = () => {
    return(
        <section className="contact-page">
			<div className="container">
				<div className="row">
					<div className="section-title text-center">
						<span className="section-title__tagline">Find the wellbeing</span>
						<h2 className="section-title__title">Login to account</h2>
					</div>
					<div className="col-xl-12">
						<form action="assets/inc/sendemail.php"
							className=" comment-one__form contact-form-validated comment-one__login-form">
							<div className="row">
								<div className="col-xl-12">
									<div className="comment-form__input-box">
										<input type="email" placeholder="Email Address" name="email"/>
									</div>
								</div>
								<div className="col-xl-12">
									<div className="comment-form__input-box">
										<input type="password" placeholder="Enter Password" name="password"/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-xl-12">
									<div className="comment-form__btn-box text-center">
										<button type="submit" className="thm-btn comment-form__btn">Login now</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
    )
}

export default Login;