import believe from "../../images/shapes/we-believe-map.png";

const Believe = () => {
    return(
        <section className="we-believe">
            <div className="we-believe-map" style = {{ backgroundImage: 'url(' + believe + ')' }}></div>
            <div className="container">
                <div className="section-title text-center">
                    <span className="section-title__tagline">welcome to charity</span>
                    <h2 className="section-title__title">We Believe that We can Save <br/> more Lifes with you</h2>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <ul className="list-unstyled we-believe__list">
                            <li className="we-believe__single wow fadeInUp" data-wow-delay="100ms">
                                <div className="we-believe__icon">
                                    <span className="icon-peace"></span>
                                </div>
                                <h3 className="we-believe__title"><a href="contact.html">Support</a></h3>
                                <p className="we-believe__text">Lorem ipsum is simply free text available in the market
                                    wesbites.</p>
                            </li>
                            <li className="we-believe__single wow fadeInUp" data-wow-delay="200ms">
                                <div className="we-believe__icon">
                                    <span className="icon-praying"></span>
                                </div>
                                <h3 className="we-believe__title"><a href="contact.html">Prayers</a></h3>
                                <p className="we-believe__text">Lorem ipsum is simply free text available in the market
                                    wesbites.</p>
                            </li>
                            <li className="we-believe__single wow fadeInUp" data-wow-delay="300ms">
                                <div className="we-believe__icon">
                                    <span className="icon-peace-1"></span>
                                </div>
                                <h3 className="we-believe__title"><a href="volunteers.html">Volunteers</a></h3>
                                <p className="we-believe__text">Lorem ipsum is simply free text available in the market
                                    wesbites.</p>
                            </li>
                            <li className="we-believe__single wow fadeInUp" data-wow-delay="400ms">
                                <div className="we-believe__icon">
                                    <span className="icon-heart"></span>
                                </div>
                                <h3 className="we-believe__title"><a href="donations.html">Donations</a></h3>
                                <p className="we-believe__text">Lorem ipsum is simply free text available in the market
                                    wesbites.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Believe;