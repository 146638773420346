import welcome1 from "../../images/village-kids-bg/welcome-one-img.jpg";
import welcome2 from "../../images/village-kids-bg/welcome-one-small-img.jpg";
import welcome3 from "../../images/shapes/welcome-one-shape-1.png";

const Welcome = () => {
    return(
        <section className="welcome-one">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6">
                        <div className="welcome-one__left">
                            <div className="welcome-one__img-box wow slideInLeft" data-wow-delay="100ms"
                                data-wow-duration="2500ms">
                                <div className="welcome-one__img">
                                    <img src={ welcome1 } alt=""/>
                                </div>
                                <div className="welcome-one__small-img">
                                    <img src={ welcome2 } alt=""/>
                                </div>
                                <div className="welcome-one-shape-1">
                                    <img src={ welcome3 } alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="welcome-one__right">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">Welcome to Jivi</span>
                                <h2 className="section-title__title">Our Goal is to Help Poor People</h2>
                            </div>
                            <p className="welcome-one__text-1">Jivi Adityodaya is the largest global crowdfunding.</p>
                            <p className="welcome-one__text-2">Lorem ipsum dolor sit amet, consectetur notted adipisicing
                                elit sed do eiusmod tempor incididunt ut labore et simply free text dolore magna aliqua
                                lonm andhn.</p>
                            <div className="welcome-one__btn-box">
                                <a href="/about" className="thm-btn welcome-one__btn">Discover More</a>
                                <div className="welcome-one__video-link">
                                    <a href="https://www.youtube.com/watch?v=Get7rqXYrbQ" className="video-popup">
                                        <div className="welcome-one__video-icon">
                                            <span className="fas fa-play"></span>
                                        </div>
                                    </a>
                                    <h4 className="welcome-one__video-text">Watch Video</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Welcome;