// common components!
import Header from "../common/header";
import Footer from "../common/footer";
import MobileNav from "../common/MobileNav";

// import from sub components!
import PageHeader from "./sub-components/PageHeader";
import Volunteer from "./sub-components/Volunteer";

const VolunteerComponent = () => {
    return(
        <>
            <div className = "page-wrapper">
                <Header/>
                <PageHeader/>
                <Volunteer/>
                <Footer/>
            </div>
            <MobileNav/>
        </>
    )
}

export default VolunteerComponent;