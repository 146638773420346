// Common
import Header from "../common/header";
import Footer from "../common/footer";
import MobileNav from "../common/MobileNav";

// sub components!
import About from "./sub-components/About";
import PageHeader from "./sub-components/PageHeader";
import Brand from "./sub-components/Brand";
import Fundraising from "./sub-components/Fundraising";
import Testimonials from "./sub-components/Testimonials";
import Volunteers from "./sub-components/Volunteers";
import CTA from "./sub-components/CTA";

const AboutComponent = () => {
    return(
        <>
            <div className = "page-wrapper">
                <Header/>
                <PageHeader/>
                <About/>
                <Brand/>
                <Fundraising/>
                <Testimonials/>
                <Volunteers/>
                <CTA/>
                <Footer/>
            </div>
            <MobileNav/>
        </>
    )
}

export default AboutComponent;