import { Link } from "react-router-dom";
import headerbg from "../../images/village-kids-bg/jivi-bg-5.png";

const PageHeader = () => {
    return(
        <section className="page-header">
            <div className="page-header-bg" style= {{ backgroundImage: 'url(' + headerbg + ')' }}>
            </div>
            <div className="container">
                <div className="page-header__inner">
                    <h2>Contact</h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to='/'>Home</Link></li>
                        <li><span>/</span></li>
                        <li className="active">Contact</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default PageHeader;